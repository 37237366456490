import { premiumFeatures } from './constants'
import { Container, Description, Icon, ListItem, TextContainer, Title } from './styles'

const ListOfFeatures = () => {
  return (
    <Container>
      {premiumFeatures.map((feature, index) => (
        <ListItem key={feature.title}>
          <Icon
            src={feature.icon}
            className='DzGwbpu5'
            alt=''
            draggable='false'
            style={{ backgroundColor: feature.iconColor }}
          />
          <TextContainer>
            <Title>{feature.title}</Title>
            <Description>{feature.description}</Description>
          </TextContainer>
        </ListItem>
      ))}
    </Container>
  )
}

export default ListOfFeatures
