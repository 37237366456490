import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Suspense } from 'react'
import Loader from './components/Loader/Loader'
import { IdentifierProvider } from './context/target.context'

const loader = document.querySelector('.loader-container')
if (loader) {
  loader.remove()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Suspense fallback={<Loader />}>
    <IdentifierProvider>
      <App />
    </IdentifierProvider>
  </Suspense>,
)
