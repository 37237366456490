import { NewInitParams } from '@/interfaces'

export const getDefaultParams = (code: string): NewInitParams => ({
  externalId: code,
  identifierType: 'phone',
  identifierValue: '',
  dynamicParams: {},
  deviceModel: '',
})

export const handleTestMode = (
  urlParams: URLSearchParams,
  initParams: NewInitParams,
): NewInitParams => {
  return {
    ...initParams,
    identifierType: (urlParams.get('it') as 'phone' | 'username' | undefined) ?? 'phone',
    identifierValue: urlParams.get('iv') ?? '',
  }
}

export const getCode = (urlParams: URLSearchParams): string | null => {
  const code = urlParams.get('code') ?? localStorage.getItem('code')
  localStorage.setItem('code', code ?? '')
  window.history.replaceState({}, document.title, window.location.pathname)
  return code
}

export const isBase64Payload = (code: string): boolean => code.length > 40

export const isTestMode = (urlParams: URLSearchParams): boolean => {
  return urlParams.get('test') === 'true'
}
