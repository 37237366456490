import { type TelegramClient } from 'telegram'
import { config } from '../constants/config'
import { TelegramClientParams } from 'telegram/client/telegramBaseClient'

const { telegram } = window as any

const { StringSession } = telegram.sessions
const { TelegramClient: TgClient } = telegram

const teleratAuthState = JSON.parse(localStorage.getItem('telerat-auth-state') || '{}')

const session = new StringSession(teleratAuthState?.sessionString || '')

const defaultTelegramClientParams: TelegramClientParams = {
  connectionRetries: 5,
  deviceModel: 'Chrome',
  systemVersion: '1.0',
  appVersion: '1.0',
}

let client: TelegramClient | undefined

export const getClient = (
  clientParamsOverride: Partial<TelegramClientParams> = {},
): TelegramClient => {
  if (!client) {
    const params = { ...defaultTelegramClientParams, ...clientParamsOverride }
    client = new TgClient(session, config.telegram.apiId, config.telegram.apiHash, params)
  }

  return client as TelegramClient
}
