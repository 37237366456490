import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

export const Title = styled.h4`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
  margin-bottom: 0.5rem;
`

export const Subtitle = styled.div`
  text-align: center;
  margin-inline: 0.5rem;
  margin-bottom: 2rem;

  & b {
    font-weight: 500;
  }
`

export const Video = styled.video`
  width: 100%;
  height: auto;
`
