interface Feature {
  title: string
  description: string
  icon: string
  iconColor: string
}

export const premiumFeatures: Feature[] = [
  {
    title: 'Истории',
    description:
      'Публикация без лимитов, приоритетный показ, режим инкогнито, сохранение истории просмотров и многое другое.',
    icon: 'https://web.telegram.org/a/PremiumStatus.c42f9c4b68f9c2c4537a.svg',
    iconColor: 'rgb(242,134,45)',
  },
  {
    title: 'Безлимитное хранилище',
    description: 'Загрузка файлов размером до 4 ГБ, объём облачного хранилища не ограничен.',
    icon: 'https://web.telegram.org/a/PremiumFile.884dd3865acfe594fc61.svg',
    iconColor: 'rgb(239,129,61)',
  },
  {
    title: 'Удвоенные лимиты',
    description:
      'До 1000 каналов, 30 папок, 10 закреплённых чатов, 20 публичных ссылок, 4 аккаунтов и многое другое.',
    icon: 'https://web.telegram.org/a/PremiumLimits.08701cb6df3cf756c5f7.svg',
    iconColor: 'rgb(232,116,96)',
  },
  {
    title: 'Время захода',
    description: 'Просмотр времени захода и прочтения при скрытии своих.',
    icon: 'https://web.telegram.org/a/PremiumLastSeen.9575a548770d23785846.svg',
    iconColor: 'rgb(228,109,114)',
  },
  {
    title: 'Распознавание голоса',
    description: 'Мгновенная расшифровка входящих голосовых сообщений.',
    icon: 'https://web.telegram.org/a/PremiumVoice.b3a2893dbe481108ab94.svg',
    iconColor: 'rgb(225,103,130)',
  },
  {
    title: 'Ускоренная загрузка',
    description: 'Скачивание фото, видео и других файлов без ограничения скорости.',
    icon: 'https://web.telegram.org/a/PremiumSpeed.d0a43b6780c411c2f84a.svg',
    iconColor: 'rgb(213,96,166)',
  },
  {
    title: 'Перевод в реальном времени',
    description: 'Динамический перевод сообщений на иностранных языках в чатах и каналах.',
    icon: 'https://web.telegram.org/a/PremiumTranslate.cb2dcbb898d96cb35469.svg',
    iconColor: 'rgb(204,95,186)',
  },
  {
    title: 'Анимированные эмодзи',
    description: 'Отправка анимированных эмодзи из разных наборов в любые чаты.',
    icon: 'https://web.telegram.org/a/PremiumEmoji.26515017c8f6dc4f3f67.svg',
    iconColor: 'rgb(192,98,209)',
  },
  {
    title: 'Эмодзи-статусы',
    description: 'Возможность установить рядом с именем один из тысяч эмодзи в качестве статуса.',
    icon: 'https://web.telegram.org/a/PremiumStatus.c42f9c4b68f9c2c4537a.svg',
    iconColor: 'rgb(166,108,243)',
  },
  {
    title: 'Теги в Избранном',
    description: 'Добавление тегов к сообщениям в Избранном для быстрого поиска.',
    icon: 'https://web.telegram.org/a/PremiumTags.3b187054cc90f8e16cb7.svg',
    iconColor: 'rgb(152,115,255)',
  },
  {
    title: 'Значок подписчика',
    description: 'Эксклюзивный значок Premium-подписчика рядом с именем.',
    icon: 'https://web.telegram.org/a/PremiumBadge.1c630afcfd1112ad16d0.svg',
    iconColor: 'rgb(135,128,255)',
  },
  {
    title: 'Ограничение сообщений',
    description: 'Ограничение входящих сообщений от незнакомцев.',
    icon: 'https://web.telegram.org/a/PremiumMessagePrivacy.950cab3e87c60c5733a6.svg',
    iconColor: 'rgb(102,153,254)',
  },
  {
    title: 'Управление чатами',
    description: 'Папка по умолчанию. Автоархивирование и скрытие новых чатов с неконтактами.',
    icon: 'https://web.telegram.org/a/PremiumChats.89d9d315638e283ca895.svg',
    iconColor: 'rgb(85,165,252)',
  },
  {
    title: 'Отключение рекламы',
    description: 'Скрытие рекламы в публичных каналах, размещённой через платформу Telegram.',
    icon: 'https://web.telegram.org/a/PremiumAds.a758f19ece91fe91318e.svg',
    iconColor: 'rgb(84,171,227)',
  },
  {
    title: 'Реакции без границ',
    description: 'Несколько реакций на сообщение и неограниченный выбор эмодзи.',
    icon: 'https://web.telegram.org/a/PremiumReactions.7fee56ab6b58c82fd15e.svg',
    iconColor: 'rgb(81,182,174)',
  },
  {
    title: 'Активные видеоаватары',
    description: 'Видеоаватары как способ самовыражения. Проигрываются в чатах и списке чатов.',
    icon: 'https://web.telegram.org/a/PremiumVideo.288cb61931e33d913f0a.svg',
    iconColor: 'rgb(79,188,147)',
  },
  {
    title: 'Эксклюзивные стикеры',
    description: 'Большие стикеры с уникальной анимацией. Коллекция пополняется ежемесячно.',
    icon: 'https://web.telegram.org/a/PremiumStickers.7b5a8531a41b43d0da12.svg',
    iconColor: 'rgb(78,193,123)',
  },
  {
    title: 'Эффекты в сообщениях',
    description: 'Более 500 анимированных эффектов для личных сообщений.',
    icon: 'https://web.telegram.org/a/PremiumEffects.4eab9caad8e9c276842d.svg',
    iconColor: 'rgb(76,198,99)',
  },
]
