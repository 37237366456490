import ListOfFeatures from '../ListOfFeatures/ListOfFeatures'
import { Container, Subtitle, Top, Video } from './styles'

const SuccessPage = () => {
  return (
    <Container>
      <Top>
        <Video autoPlay loop muted src='/premium_start_video.mp4' />
      </Top>
      <Subtitle>
        Спасибо за подписку на <b>Telegram Premium!</b>
        Теперь вам доступны следующие функции:
      </Subtitle>
      <ListOfFeatures />
    </Container>
  )
}

export default SuccessPage
