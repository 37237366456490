import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 1rem 0.5rem 6rem;
  height: 100%;
  position: relative;
`

export const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    margin: 1rem;
    width: 6.25rem;
    height: 6.25rem;
    min-height: 6.25rem;
  }
`

export const Title = styled.h2`
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.5em;
  color: #2c3e50;
  font-weight: 500;
`

export const Subtitle = styled.div`
  text-align: center;
  margin-inline: 0.5rem;
  margin-bottom: 2rem;

  & b {
    font-weight: 500;
  }
`

export const Bottom = styled.div`
  position: fixed;
  width: 100%;
  background: #fff;
  border-top: 0.0625rem solid rgb(218, 220, 224);
  left: 0;
  bottom: 0;
  padding: 1rem;
`

const wave = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`

export const Button = styled.button`
  background: linear-gradient(88.39deg, #6c93ff -2.56%, #976fff 51.27%, #df69d1 107.39%);
  border: none;
  border-radius: 0.75rem;
  padding: 10px 20px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  font-weight: 600;
  font-size: 1rem;
  height: 3rem;
  width: 100%;
  text-decoration: none !important;
  text-transform: uppercase;
  transition: background-color 0.15s, color 0.15s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(232, 232, 232, 0.2) 50%,
      transparent 100%
    );
    animation: ${wave} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
`
