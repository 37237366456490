import './Loader.css'

export interface LoaderProps {
  inline?: boolean
  text?: string
}

const Loader: React.FC<LoaderProps> = ({ inline, text }) => {
  return (
    <div
      className='loader-container'
      style={inline ? { position: 'static', transform: 'unset' } : {}}
    >
      <div className='spinner'></div>
      <p className='loader-text'>{text}</p>
    </div>
  )
}

export default Loader
