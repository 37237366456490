import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`

export const Title = styled.h4`
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.5em;
  color: #2c3e50;
`

export const ErrorMessage = styled.div`
  font-size: 1.2rem;
  text-align: center;
  color: #aaaaaa;
  margin-bottom: 2rem;
`

export const Image = styled.img`
  margin: 1rem;
  width: 6.25rem;
  height: 6.25rem;
`

export const RetryButton = styled.button`
  background: linear-gradient(88.39deg, #6c93ff -2.56%, #976fff 51.27%, #df69d1 107.39%);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.95);
  }
`
