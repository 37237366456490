const Logo = () => {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M47.3065 75.4791L27.7285 87.4727C26.0852 88.4794 23.9369 87.9633 22.9303 86.32C22.4386 85.5175 22.292 84.5503 22.5238 83.638L25.5544 71.7092C26.5877 67.6424 29.3706 64.2428 33.1533 62.4267L54.512 52.172C55.9226 51.4947 56.5171 49.8021 55.8398 48.3915C55.2914 47.2491 54.051 46.6098 52.8023 46.826L29.0274 50.942C24.4362 51.7369 19.7279 50.4691 16.1566 47.4762L8.6459 41.182C7.16885 39.9442 6.97491 37.7434 8.21272 36.2664C8.81474 35.548 9.68047 35.102 10.6149 35.0289L33.5623 33.233C35.496 33.0817 37.1812 31.8579 37.9235 30.066L46.7762 8.69618C47.5137 6.91577 49.5549 6.07037 51.3354 6.80793C52.1903 7.16207 52.8695 7.84129 53.2236 8.69618L62.0763 30.066C62.8186 31.8579 64.5038 33.0817 66.4375 33.233L89.511 35.0387C91.4322 35.1891 92.8678 36.8685 92.7175 38.7897C92.6451 39.714 92.208 40.5714 91.5025 41.1729L73.9053 56.1749C72.4277 57.4345 71.7831 59.4175 72.2375 61.3053L77.6474 83.7792C78.0984 85.6528 76.9452 87.5373 75.0716 87.9883C74.1713 88.205 73.2218 88.055 72.4322 87.5713L52.6933 75.4791C51.0404 74.4666 48.9594 74.4666 47.3065 75.4791Z'
        fill='white'
      />
      <path
        d='M47.3065 75.4791L27.7285 87.4727C26.0852 88.4794 23.9369 87.9633 22.9303 86.32C22.4386 85.5175 22.292 84.5503 22.5238 83.638L25.5544 71.7092C26.5877 67.6424 29.3706 64.2428 33.1533 62.4267L54.512 52.172C55.9226 51.4947 56.5171 49.8021 55.8398 48.3915C55.2914 47.2491 54.051 46.6098 52.8023 46.826L29.0274 50.942C24.4362 51.7369 19.7279 50.4691 16.1566 47.4762L8.6459 41.182C7.16885 39.9442 6.97491 37.7434 8.21272 36.2664C8.81474 35.548 9.68047 35.102 10.6149 35.0289L33.5623 33.233C35.496 33.0817 37.1812 31.8579 37.9235 30.066L46.7762 8.69618C47.5137 6.91577 49.5549 6.07037 51.3354 6.80793C52.1903 7.16207 52.8695 7.84129 53.2236 8.69618L62.0763 30.066C62.8186 31.8579 64.5038 33.0817 66.4375 33.233L89.511 35.0387C91.4322 35.1891 92.8678 36.8685 92.7175 38.7897C92.6451 39.714 92.208 40.5714 91.5025 41.1729L73.9053 56.1749C72.4277 57.4345 71.7831 59.4175 72.2375 61.3053L77.6474 83.7792C78.0984 85.6528 76.9452 87.5373 75.0716 87.9883C74.1713 88.205 73.2218 88.055 72.4322 87.5713L52.6933 75.4791C51.0404 74.4666 48.9594 74.4666 47.3065 75.4791Z'
        fill='url(#paint0_linear_124_4926)'
      />
      <path
        d='M47.3065 75.4791L27.7285 87.4727C26.0852 88.4794 23.9369 87.9633 22.9303 86.32C22.4386 85.5175 22.292 84.5503 22.5238 83.638L25.5544 71.7092C26.5877 67.6424 29.3706 64.2428 33.1533 62.4267L54.512 52.172C55.9226 51.4947 56.5171 49.8021 55.8398 48.3915C55.2914 47.2491 54.051 46.6098 52.8023 46.826L29.0274 50.942C24.4362 51.7369 19.7279 50.4691 16.1566 47.4762L8.6459 41.182C7.16885 39.9442 6.97491 37.7434 8.21272 36.2664C8.81474 35.548 9.68047 35.102 10.6149 35.0289L33.5623 33.233C35.496 33.0817 37.1812 31.8579 37.9235 30.066L46.7762 8.69618C47.5137 6.91577 49.5549 6.07037 51.3354 6.80793C52.1903 7.16207 52.8695 7.84129 53.2236 8.69618L62.0763 30.066C62.8186 31.8579 64.5038 33.0817 66.4375 33.233L89.511 35.0387C91.4322 35.1891 92.8678 36.8685 92.7175 38.7897C92.6451 39.714 92.208 40.5714 91.5025 41.1729L73.9053 56.1749C72.4277 57.4345 71.7831 59.4175 72.2375 61.3053L77.6474 83.7792C78.0984 85.6528 76.9452 87.5373 75.0716 87.9883C74.1713 88.205 73.2218 88.055 72.4322 87.5713L52.6933 75.4791C51.0404 74.4666 48.9594 74.4666 47.3065 75.4791Z'
        stroke='url(#paint1_linear_124_4926)'
        strokeWidth='1.66667'
        style={{ mixBlendMode: 'soft-light' }}
      />
      <g opacity='0.3'>
        <path
          d='M87.1958 21.9339C87.1162 22.1962 87.0513 22.4925 86.9933 22.8247C86.9318 22.4938 86.8645 22.1997 86.7836 21.9402C86.6432 21.4904 86.4523 21.1117 86.1497 20.81C85.8465 20.5076 85.4699 20.3211 85.026 20.1879C84.7934 20.1181 84.5335 20.0605 84.245 20.0089C85.0724 19.8756 85.7113 19.6695 86.1663 19.2123C86.4708 18.9063 86.6622 18.5214 86.8003 18.066C86.8792 17.806 86.9437 17.5126 87.0013 17.1838C87.0613 17.5123 87.1276 17.805 87.2077 18.0641C87.3478 18.517 87.5394 18.8994 87.8429 19.2038C88.2893 19.6514 88.9123 19.8586 89.7194 19.9966C88.9076 20.1302 88.2795 20.3367 87.8304 20.7875C87.5256 21.0935 87.3341 21.4785 87.1958 21.9339Z'
          fill='white'
        />
        <path
          d='M87.1958 21.9339C87.1162 22.1962 87.0513 22.4925 86.9933 22.8247C86.9318 22.4938 86.8645 22.1997 86.7836 21.9402C86.6432 21.4904 86.4523 21.1117 86.1497 20.81C85.8465 20.5076 85.4699 20.3211 85.026 20.1879C84.7934 20.1181 84.5335 20.0605 84.245 20.0089C85.0724 19.8756 85.7113 19.6695 86.1663 19.2123C86.4708 18.9063 86.6622 18.5214 86.8003 18.066C86.8792 17.806 86.9437 17.5126 87.0013 17.1838C87.0613 17.5123 87.1276 17.805 87.2077 18.0641C87.3478 18.517 87.5394 18.8994 87.8429 19.2038C88.2893 19.6514 88.9123 19.8586 89.7194 19.9966C88.9076 20.1302 88.2795 20.3367 87.8304 20.7875C87.5256 21.0935 87.3341 21.4785 87.1958 21.9339Z'
          fill='url(#paint2_linear_124_4926)'
        />
        <path
          d='M87.1958 21.9339C87.1162 22.1962 87.0513 22.4925 86.9933 22.8247C86.9318 22.4938 86.8645 22.1997 86.7836 21.9402C86.6432 21.4904 86.4523 21.1117 86.1497 20.81C85.8465 20.5076 85.4699 20.3211 85.026 20.1879C84.7934 20.1181 84.5335 20.0605 84.245 20.0089C85.0724 19.8756 85.7113 19.6695 86.1663 19.2123C86.4708 18.9063 86.6622 18.5214 86.8003 18.066C86.8792 17.806 86.9437 17.5126 87.0013 17.1838C87.0613 17.5123 87.1276 17.805 87.2077 18.0641C87.3478 18.517 87.5394 18.8994 87.8429 19.2038C88.2893 19.6514 88.9123 19.8586 89.7194 19.9966C88.9076 20.1302 88.2795 20.3367 87.8304 20.7875C87.5256 21.0935 87.3341 21.4785 87.1958 21.9339Z'
          stroke='url(#paint3_linear_124_4926)'
          style={{ mixBlendMode: 'soft-light' }}
        />
      </g>
      <path
        d='M4.19585 55.9339C4.11625 56.1962 4.05127 56.4925 3.99329 56.8247C3.93183 56.4938 3.86453 56.1997 3.78356 55.9402C3.64322 55.4904 3.45227 55.1117 3.14971 54.81C2.84652 54.5076 2.46991 54.3211 2.02602 54.1879C1.79338 54.1181 1.53351 54.0605 1.24504 54.0089C2.07244 53.8756 2.7113 53.6695 3.16632 53.2123C3.47085 52.9063 3.66216 52.5214 3.80029 52.066C3.87917 51.806 3.94369 51.5126 4.00128 51.1838C4.06133 51.5123 4.12764 51.805 4.20775 52.0641C4.34777 52.517 4.53935 52.8994 4.84292 53.2038C5.28932 53.6514 5.9123 53.8586 6.71942 53.9966C5.9076 54.1302 5.27951 54.3367 4.83043 54.7875C4.52556 55.0935 4.33407 55.4785 4.19585 55.9339Z'
        fill='white'
      />
      <path
        d='M4.19585 55.9339C4.11625 56.1962 4.05127 56.4925 3.99329 56.8247C3.93183 56.4938 3.86453 56.1997 3.78356 55.9402C3.64322 55.4904 3.45227 55.1117 3.14971 54.81C2.84652 54.5076 2.46991 54.3211 2.02602 54.1879C1.79338 54.1181 1.53351 54.0605 1.24504 54.0089C2.07244 53.8756 2.7113 53.6695 3.16632 53.2123C3.47085 52.9063 3.66216 52.5214 3.80029 52.066C3.87917 51.806 3.94369 51.5126 4.00128 51.1838C4.06133 51.5123 4.12764 51.805 4.20775 52.0641C4.34777 52.517 4.53935 52.8994 4.84292 53.2038C5.28932 53.6514 5.9123 53.8586 6.71942 53.9966C5.9076 54.1302 5.27951 54.3367 4.83043 54.7875C4.52556 55.0935 4.33407 55.4785 4.19585 55.9339Z'
        fill='url(#paint4_linear_124_4926)'
      />
      <path
        d='M4.19585 55.9339C4.11625 56.1962 4.05127 56.4925 3.99329 56.8247C3.93183 56.4938 3.86453 56.1997 3.78356 55.9402C3.64322 55.4904 3.45227 55.1117 3.14971 54.81C2.84652 54.5076 2.46991 54.3211 2.02602 54.1879C1.79338 54.1181 1.53351 54.0605 1.24504 54.0089C2.07244 53.8756 2.7113 53.6695 3.16632 53.2123C3.47085 52.9063 3.66216 52.5214 3.80029 52.066C3.87917 51.806 3.94369 51.5126 4.00128 51.1838C4.06133 51.5123 4.12764 51.805 4.20775 52.0641C4.34777 52.517 4.53935 52.8994 4.84292 53.2038C5.28932 53.6514 5.9123 53.8586 6.71942 53.9966C5.9076 54.1302 5.27951 54.3367 4.83043 54.7875C4.52556 55.0935 4.33407 55.4785 4.19585 55.9339Z'
        stroke='url(#paint5_linear_124_4926)'
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        d='M32.2998 21.9989C30.8424 22.1689 29.8077 22.3889 29.1266 23.0726C28.7649 23.4357 28.5337 23.8961 28.3644 24.4537C28.2101 24.9622 28.0996 25.5784 27.9963 26.3166C27.8832 25.5784 27.7672 24.9658 27.6101 24.4625C27.4383 23.9117 27.2078 23.4589 26.8489 23.101C26.4893 22.7424 26.0395 22.5175 25.4966 22.3546C25.0022 22.2063 24.4041 22.1019 23.6882 22.0024C25.1497 21.8322 26.187 21.6128 26.8693 20.9272C27.2306 20.5641 27.4616 20.1038 27.6307 19.5462C27.7848 19.0384 27.8952 18.4232 27.9984 17.6862C28.1082 18.4232 28.2226 19.0372 28.3791 19.5432C28.5506 20.0979 28.7819 20.5553 29.1422 20.9165C29.8199 21.5961 30.847 21.8183 32.2998 21.9989ZM32.5 22C32.5 21.9978 32.5002 21.9956 32.5004 21.9935L32.5012 22L32.5026 22.0121C32.5005 22.0068 32.5 22.0023 32.5 22ZM23.4986 22.024L23.4382 22.4728L23.4987 22.024L23.4986 22.024Z'
        fill='white'
      />
      <path
        d='M32.2998 21.9989C30.8424 22.1689 29.8077 22.3889 29.1266 23.0726C28.7649 23.4357 28.5337 23.8961 28.3644 24.4537C28.2101 24.9622 28.0996 25.5784 27.9963 26.3166C27.8832 25.5784 27.7672 24.9658 27.6101 24.4625C27.4383 23.9117 27.2078 23.4589 26.8489 23.101C26.4893 22.7424 26.0395 22.5175 25.4966 22.3546C25.0022 22.2063 24.4041 22.1019 23.6882 22.0024C25.1497 21.8322 26.187 21.6128 26.8693 20.9272C27.2306 20.5641 27.4616 20.1038 27.6307 19.5462C27.7848 19.0384 27.8952 18.4232 27.9984 17.6862C28.1082 18.4232 28.2226 19.0372 28.3791 19.5432C28.5506 20.0979 28.7819 20.5553 29.1422 20.9165C29.8199 21.5961 30.847 21.8183 32.2998 21.9989ZM32.5 22C32.5 21.9978 32.5002 21.9956 32.5004 21.9935L32.5012 22L32.5026 22.0121C32.5005 22.0068 32.5 22.0023 32.5 22ZM23.4986 22.024L23.4382 22.4728L23.4987 22.024L23.4986 22.024Z'
        fill='url(#paint6_linear_124_4926)'
      />
      <path
        d='M32.2998 21.9989C30.8424 22.1689 29.8077 22.3889 29.1266 23.0726C28.7649 23.4357 28.5337 23.8961 28.3644 24.4537C28.2101 24.9622 28.0996 25.5784 27.9963 26.3166C27.8832 25.5784 27.7672 24.9658 27.6101 24.4625C27.4383 23.9117 27.2078 23.4589 26.8489 23.101C26.4893 22.7424 26.0395 22.5175 25.4966 22.3546C25.0022 22.2063 24.4041 22.1019 23.6882 22.0024C25.1497 21.8322 26.187 21.6128 26.8693 20.9272C27.2306 20.5641 27.4616 20.1038 27.6307 19.5462C27.7848 19.0384 27.8952 18.4232 27.9984 17.6862C28.1082 18.4232 28.2226 19.0372 28.3791 19.5432C28.5506 20.0979 28.7819 20.5553 29.1422 20.9165C29.8199 21.5961 30.847 21.8183 32.2998 21.9989ZM32.5 22C32.5 21.9978 32.5002 21.9956 32.5004 21.9935L32.5012 22L32.5026 22.0121C32.5005 22.0068 32.5 22.0023 32.5 22ZM23.4986 22.024L23.4382 22.4728L23.4987 22.024L23.4986 22.024Z'
        stroke='url(#paint7_linear_124_4926)'
        style={{ mixBlendMode: 'soft-light' }}
      />
      <path
        d='M71.2998 12.9989C69.8424 13.1689 68.8077 13.3889 68.1266 14.0726C67.7649 14.4357 67.5337 14.8961 67.3644 15.4537C67.2101 15.9622 67.0996 16.5784 66.9963 17.3166C66.8832 16.5784 66.7672 15.9658 66.6101 15.4625C66.4383 14.9117 66.2078 14.4589 65.8489 14.101C65.4893 13.7424 65.0395 13.5175 64.4966 13.3546C64.0022 13.2063 63.4041 13.1019 62.6882 13.0024C64.1497 12.8322 65.187 12.6128 65.8693 11.9272C66.2306 11.5641 66.4616 11.1038 66.6307 10.5462C66.7848 10.0384 66.8952 9.42317 66.9984 8.68625C67.1082 9.42315 67.2226 10.0372 67.3791 10.5432C67.5506 11.0979 67.7819 11.5553 68.1422 11.9165C68.8199 12.5961 69.847 12.8183 71.2998 12.9989ZM71.5 13C71.5 12.9978 71.5002 12.9956 71.5004 12.9935L71.5012 13L71.5026 13.0121C71.5005 13.0068 71.5 13.0023 71.5 13ZM62.4986 13.024L62.4382 13.4728L62.4987 13.024L62.4986 13.024Z'
        fill='white'
      />
      <path
        d='M71.2998 12.9989C69.8424 13.1689 68.8077 13.3889 68.1266 14.0726C67.7649 14.4357 67.5337 14.8961 67.3644 15.4537C67.2101 15.9622 67.0996 16.5784 66.9963 17.3166C66.8832 16.5784 66.7672 15.9658 66.6101 15.4625C66.4383 14.9117 66.2078 14.4589 65.8489 14.101C65.4893 13.7424 65.0395 13.5175 64.4966 13.3546C64.0022 13.2063 63.4041 13.1019 62.6882 13.0024C64.1497 12.8322 65.187 12.6128 65.8693 11.9272C66.2306 11.5641 66.4616 11.1038 66.6307 10.5462C66.7848 10.0384 66.8952 9.42317 66.9984 8.68625C67.1082 9.42315 67.2226 10.0372 67.3791 10.5432C67.5506 11.0979 67.7819 11.5553 68.1422 11.9165C68.8199 12.5961 69.847 12.8183 71.2998 12.9989ZM71.5 13C71.5 12.9978 71.5002 12.9956 71.5004 12.9935L71.5012 13L71.5026 13.0121C71.5005 13.0068 71.5 13.0023 71.5 13ZM62.4986 13.024L62.4382 13.4728L62.4987 13.024L62.4986 13.024Z'
        fill='url(#paint8_linear_124_4926)'
      />
      <path
        d='M71.2998 12.9989C69.8424 13.1689 68.8077 13.3889 68.1266 14.0726C67.7649 14.4357 67.5337 14.8961 67.3644 15.4537C67.2101 15.9622 67.0996 16.5784 66.9963 17.3166C66.8832 16.5784 66.7672 15.9658 66.6101 15.4625C66.4383 14.9117 66.2078 14.4589 65.8489 14.101C65.4893 13.7424 65.0395 13.5175 64.4966 13.3546C64.0022 13.2063 63.4041 13.1019 62.6882 13.0024C64.1497 12.8322 65.187 12.6128 65.8693 11.9272C66.2306 11.5641 66.4616 11.1038 66.6307 10.5462C66.7848 10.0384 66.8952 9.42317 66.9984 8.68625C67.1082 9.42315 67.2226 10.0372 67.3791 10.5432C67.5506 11.0979 67.7819 11.5553 68.1422 11.9165C68.8199 12.5961 69.847 12.8183 71.2998 12.9989ZM71.5 13C71.5 12.9978 71.5002 12.9956 71.5004 12.9935L71.5012 13L71.5026 13.0121C71.5005 13.0068 71.5 13.0023 71.5 13ZM62.4986 13.024L62.4382 13.4728L62.4987 13.024L62.4986 13.024Z'
        stroke='url(#paint9_linear_124_4926)'
        style={{ mixBlendMode: 'soft-light' }}
      />
      <g opacity='0.3'>
        <path
          d='M15.2998 70.9989C13.8424 71.1689 12.8077 71.3889 12.1266 72.0726C11.7649 72.4357 11.5337 72.8961 11.3644 73.4537C11.2101 73.9622 11.0996 74.5784 10.9963 75.3166C10.8832 74.5784 10.7672 73.9658 10.6101 73.4625C10.4383 72.9117 10.2078 72.4589 9.84888 72.101C9.4893 71.7424 9.03946 71.5175 8.4966 71.3546C8.00217 71.2063 7.40406 71.1019 6.68822 71.0024C8.14969 70.8322 9.18698 70.6128 9.8693 69.9272C10.2306 69.5641 10.4616 69.1038 10.6307 68.5462C10.7848 68.0384 10.8952 67.4232 10.9984 66.6862C11.1082 67.4232 11.2226 68.0372 11.3791 68.5432C11.5506 69.0979 11.7819 69.5553 12.1422 69.9165C12.8199 70.5961 13.847 70.8183 15.2998 70.9989ZM15.5 71C15.5 70.9978 15.5002 70.9956 15.5004 70.9935L15.5012 71L15.5026 71.0121C15.5005 71.0068 15.5 71.0023 15.5 71ZM6.4986 71.024L6.43822 71.4728L6.49866 71.024L6.4986 71.024Z'
          fill='white'
        />
        <path
          d='M15.2998 70.9989C13.8424 71.1689 12.8077 71.3889 12.1266 72.0726C11.7649 72.4357 11.5337 72.8961 11.3644 73.4537C11.2101 73.9622 11.0996 74.5784 10.9963 75.3166C10.8832 74.5784 10.7672 73.9658 10.6101 73.4625C10.4383 72.9117 10.2078 72.4589 9.84888 72.101C9.4893 71.7424 9.03946 71.5175 8.4966 71.3546C8.00217 71.2063 7.40406 71.1019 6.68822 71.0024C8.14969 70.8322 9.18698 70.6128 9.8693 69.9272C10.2306 69.5641 10.4616 69.1038 10.6307 68.5462C10.7848 68.0384 10.8952 67.4232 10.9984 66.6862C11.1082 67.4232 11.2226 68.0372 11.3791 68.5432C11.5506 69.0979 11.7819 69.5553 12.1422 69.9165C12.8199 70.5961 13.847 70.8183 15.2998 70.9989ZM15.5 71C15.5 70.9978 15.5002 70.9956 15.5004 70.9935L15.5012 71L15.5026 71.0121C15.5005 71.0068 15.5 71.0023 15.5 71ZM6.4986 71.024L6.43822 71.4728L6.49866 71.024L6.4986 71.024Z'
          fill='url(#paint10_linear_124_4926)'
        />
        <path
          d='M15.2998 70.9989C13.8424 71.1689 12.8077 71.3889 12.1266 72.0726C11.7649 72.4357 11.5337 72.8961 11.3644 73.4537C11.2101 73.9622 11.0996 74.5784 10.9963 75.3166C10.8832 74.5784 10.7672 73.9658 10.6101 73.4625C10.4383 72.9117 10.2078 72.4589 9.84888 72.101C9.4893 71.7424 9.03946 71.5175 8.4966 71.3546C8.00217 71.2063 7.40406 71.1019 6.68822 71.0024C8.14969 70.8322 9.18698 70.6128 9.8693 69.9272C10.2306 69.5641 10.4616 69.1038 10.6307 68.5462C10.7848 68.0384 10.8952 67.4232 10.9984 66.6862C11.1082 67.4232 11.2226 68.0372 11.3791 68.5432C11.5506 69.0979 11.7819 69.5553 12.1422 69.9165C12.8199 70.5961 13.847 70.8183 15.2998 70.9989ZM15.5 71C15.5 70.9978 15.5002 70.9956 15.5004 70.9935L15.5012 71L15.5026 71.0121C15.5005 71.0068 15.5 71.0023 15.5 71ZM6.4986 71.024L6.43822 71.4728L6.49866 71.024L6.4986 71.024Z'
          stroke='url(#paint11_linear_124_4926)'
          style={{ mixBlendMode: 'soft-light' }}
        />
      </g>
      <path
        d='M96.2998 75.9989C94.8424 76.1689 93.8077 76.3889 93.1266 77.0726C92.7649 77.4357 92.5337 77.8961 92.3644 78.4537C92.2101 78.9622 92.0996 79.5784 91.9963 80.3166C91.8832 79.5784 91.7672 78.9658 91.6101 78.4625C91.4383 77.9117 91.2078 77.4589 90.8489 77.101C90.4893 76.7424 90.0395 76.5175 89.4966 76.3546C89.0022 76.2063 88.4041 76.1019 87.6882 76.0024C89.1497 75.8322 90.187 75.6128 90.8693 74.9272C91.2306 74.5641 91.4616 74.1038 91.6307 73.5462C91.7848 73.0384 91.8952 72.4232 91.9984 71.6862C92.1082 72.4232 92.2226 73.0372 92.3791 73.5432C92.5506 74.0979 92.7819 74.5553 93.1422 74.9165C93.8199 75.5961 94.847 75.8183 96.2998 75.9989ZM96.5 76C96.5 75.9978 96.5002 75.9956 96.5004 75.9935L96.5012 76L96.5026 76.0121C96.5005 76.0068 96.5 76.0023 96.5 76ZM87.4986 76.024L87.4382 76.4728L87.4987 76.024L87.4986 76.024Z'
        fill='white'
      />
      <path
        d='M96.2998 75.9989C94.8424 76.1689 93.8077 76.3889 93.1266 77.0726C92.7649 77.4357 92.5337 77.8961 92.3644 78.4537C92.2101 78.9622 92.0996 79.5784 91.9963 80.3166C91.8832 79.5784 91.7672 78.9658 91.6101 78.4625C91.4383 77.9117 91.2078 77.4589 90.8489 77.101C90.4893 76.7424 90.0395 76.5175 89.4966 76.3546C89.0022 76.2063 88.4041 76.1019 87.6882 76.0024C89.1497 75.8322 90.187 75.6128 90.8693 74.9272C91.2306 74.5641 91.4616 74.1038 91.6307 73.5462C91.7848 73.0384 91.8952 72.4232 91.9984 71.6862C92.1082 72.4232 92.2226 73.0372 92.3791 73.5432C92.5506 74.0979 92.7819 74.5553 93.1422 74.9165C93.8199 75.5961 94.847 75.8183 96.2998 75.9989ZM96.5 76C96.5 75.9978 96.5002 75.9956 96.5004 75.9935L96.5012 76L96.5026 76.0121C96.5005 76.0068 96.5 76.0023 96.5 76ZM87.4986 76.024L87.4382 76.4728L87.4987 76.024L87.4986 76.024Z'
        fill='url(#paint12_linear_124_4926)'
      />
      <path
        d='M96.2998 75.9989C94.8424 76.1689 93.8077 76.3889 93.1266 77.0726C92.7649 77.4357 92.5337 77.8961 92.3644 78.4537C92.2101 78.9622 92.0996 79.5784 91.9963 80.3166C91.8832 79.5784 91.7672 78.9658 91.6101 78.4625C91.4383 77.9117 91.2078 77.4589 90.8489 77.101C90.4893 76.7424 90.0395 76.5175 89.4966 76.3546C89.0022 76.2063 88.4041 76.1019 87.6882 76.0024C89.1497 75.8322 90.187 75.6128 90.8693 74.9272C91.2306 74.5641 91.4616 74.1038 91.6307 73.5462C91.7848 73.0384 91.8952 72.4232 91.9984 71.6862C92.1082 72.4232 92.2226 73.0372 92.3791 73.5432C92.5506 74.0979 92.7819 74.5553 93.1422 74.9165C93.8199 75.5961 94.847 75.8183 96.2998 75.9989ZM96.5 76C96.5 75.9978 96.5002 75.9956 96.5004 75.9935L96.5012 76L96.5026 76.0121C96.5005 76.0068 96.5 76.0023 96.5 76ZM87.4986 76.024L87.4382 76.4728L87.4987 76.024L87.4986 76.024Z'
        stroke='url(#paint13_linear_124_4926)'
        style={{ mixBlendMode: 'soft-light' }}
      />
      <g opacity='0.3'>
        <path
          d='M15.1958 16.9339C15.1162 17.1962 15.0513 17.4925 14.9933 17.8247C14.9318 17.4938 14.8645 17.1997 14.7836 16.9402C14.6432 16.4904 14.4523 16.1117 14.1497 15.81C13.8465 15.5076 13.4699 15.3211 13.026 15.1879C12.7934 15.1181 12.5335 15.0605 12.245 15.0089C13.0724 14.8756 13.7113 14.6695 14.1663 14.2123C14.4708 13.9063 14.6622 13.5214 14.8003 13.066C14.8792 12.806 14.9437 12.5126 15.0013 12.1838C15.0613 12.5123 15.1276 12.805 15.2077 13.0641C15.3478 13.517 15.5394 13.8994 15.8429 14.2038C16.2893 14.6514 16.9123 14.8586 17.7194 14.9966C16.9076 15.1302 16.2795 15.3367 15.8304 15.7875C15.5256 16.0935 15.3341 16.4785 15.1958 16.9339Z'
          fill='white'
        />
        <path
          d='M15.1958 16.9339C15.1162 17.1962 15.0513 17.4925 14.9933 17.8247C14.9318 17.4938 14.8645 17.1997 14.7836 16.9402C14.6432 16.4904 14.4523 16.1117 14.1497 15.81C13.8465 15.5076 13.4699 15.3211 13.026 15.1879C12.7934 15.1181 12.5335 15.0605 12.245 15.0089C13.0724 14.8756 13.7113 14.6695 14.1663 14.2123C14.4708 13.9063 14.6622 13.5214 14.8003 13.066C14.8792 12.806 14.9437 12.5126 15.0013 12.1838C15.0613 12.5123 15.1276 12.805 15.2077 13.0641C15.3478 13.517 15.5394 13.8994 15.8429 14.2038C16.2893 14.6514 16.9123 14.8586 17.7194 14.9966C16.9076 15.1302 16.2795 15.3367 15.8304 15.7875C15.5256 16.0935 15.3341 16.4785 15.1958 16.9339Z'
          fill='url(#paint14_linear_124_4926)'
        />
        <path
          d='M15.1958 16.9339C15.1162 17.1962 15.0513 17.4925 14.9933 17.8247C14.9318 17.4938 14.8645 17.1997 14.7836 16.9402C14.6432 16.4904 14.4523 16.1117 14.1497 15.81C13.8465 15.5076 13.4699 15.3211 13.026 15.1879C12.7934 15.1181 12.5335 15.0605 12.245 15.0089C13.0724 14.8756 13.7113 14.6695 14.1663 14.2123C14.4708 13.9063 14.6622 13.5214 14.8003 13.066C14.8792 12.806 14.9437 12.5126 15.0013 12.1838C15.0613 12.5123 15.1276 12.805 15.2077 13.0641C15.3478 13.517 15.5394 13.8994 15.8429 14.2038C16.2893 14.6514 16.9123 14.8586 17.7194 14.9966C16.9076 15.1302 16.2795 15.3367 15.8304 15.7875C15.5256 16.0935 15.3341 16.4785 15.1958 16.9339Z'
          stroke='url(#paint15_linear_124_4926)'
          style={{ mixBlendMode: 'soft-light' }}
        />
      </g>
      <g opacity='0.6'>
        <path
          d='M20.1958 60.9339C20.1162 61.1962 20.0513 61.4925 19.9933 61.8247C19.9318 61.4938 19.8645 61.1997 19.7836 60.9402C19.6432 60.4904 19.4523 60.1117 19.1497 59.81C18.8465 59.5076 18.4699 59.3211 18.026 59.1879C17.7934 59.1181 17.5335 59.0605 17.245 59.0089C18.0724 58.8756 18.7113 58.6695 19.1663 58.2123C19.4708 57.9063 19.6622 57.5214 19.8003 57.066C19.8792 56.806 19.9437 56.5126 20.0013 56.1838C20.0613 56.5123 20.1276 56.805 20.2077 57.0641C20.3478 57.517 20.5394 57.8994 20.8429 58.2038C21.2893 58.6514 21.9123 58.8586 22.7194 58.9966C21.9076 59.1302 21.2795 59.3367 20.8304 59.7875C20.5256 60.0935 20.3341 60.4785 20.1958 60.9339Z'
          fill='white'
        />
        <path
          d='M20.1958 60.9339C20.1162 61.1962 20.0513 61.4925 19.9933 61.8247C19.9318 61.4938 19.8645 61.1997 19.7836 60.9402C19.6432 60.4904 19.4523 60.1117 19.1497 59.81C18.8465 59.5076 18.4699 59.3211 18.026 59.1879C17.7934 59.1181 17.5335 59.0605 17.245 59.0089C18.0724 58.8756 18.7113 58.6695 19.1663 58.2123C19.4708 57.9063 19.6622 57.5214 19.8003 57.066C19.8792 56.806 19.9437 56.5126 20.0013 56.1838C20.0613 56.5123 20.1276 56.805 20.2077 57.0641C20.3478 57.517 20.5394 57.8994 20.8429 58.2038C21.2893 58.6514 21.9123 58.8586 22.7194 58.9966C21.9076 59.1302 21.2795 59.3367 20.8304 59.7875C20.5256 60.0935 20.3341 60.4785 20.1958 60.9339Z'
          fill='url(#paint16_linear_124_4926)'
        />
        <path
          d='M20.1958 60.9339C20.1162 61.1962 20.0513 61.4925 19.9933 61.8247C19.9318 61.4938 19.8645 61.1997 19.7836 60.9402C19.6432 60.4904 19.4523 60.1117 19.1497 59.81C18.8465 59.5076 18.4699 59.3211 18.026 59.1879C17.7934 59.1181 17.5335 59.0605 17.245 59.0089C18.0724 58.8756 18.7113 58.6695 19.1663 58.2123C19.4708 57.9063 19.6622 57.5214 19.8003 57.066C19.8792 56.806 19.9437 56.5126 20.0013 56.1838C20.0613 56.5123 20.1276 56.805 20.2077 57.0641C20.3478 57.517 20.5394 57.8994 20.8429 58.2038C21.2893 58.6514 21.9123 58.8586 22.7194 58.9966C21.9076 59.1302 21.2795 59.3367 20.8304 59.7875C20.5256 60.0935 20.3341 60.4785 20.1958 60.9339Z'
          stroke='url(#paint17_linear_124_4926)'
          style={{ mixBlendMode: 'soft-light' }}
        />
      </g>
      <g opacity='0.3'>
        <path
          d='M83.1958 64.9339C83.1162 65.1962 83.0513 65.4925 82.9933 65.8247C82.9318 65.4938 82.8645 65.1997 82.7836 64.9402C82.6432 64.4904 82.4523 64.1117 82.1497 63.81C81.8465 63.5076 81.4699 63.3211 81.026 63.1879C80.7934 63.1181 80.5335 63.0605 80.245 63.0089C81.0724 62.8756 81.7113 62.6695 82.1663 62.2123C82.4708 61.9063 82.6622 61.5214 82.8003 61.066C82.8792 60.806 82.9437 60.5126 83.0013 60.1838C83.0613 60.5123 83.1276 60.805 83.2077 61.0641C83.3478 61.517 83.5394 61.8994 83.8429 62.2038C84.2893 62.6514 84.9123 62.8586 85.7194 62.9966C84.9076 63.1302 84.2795 63.3367 83.8304 63.7875C83.5256 64.0935 83.3341 64.4785 83.1958 64.9339Z'
          fill='white'
        />
        <path
          d='M83.1958 64.9339C83.1162 65.1962 83.0513 65.4925 82.9933 65.8247C82.9318 65.4938 82.8645 65.1997 82.7836 64.9402C82.6432 64.4904 82.4523 64.1117 82.1497 63.81C81.8465 63.5076 81.4699 63.3211 81.026 63.1879C80.7934 63.1181 80.5335 63.0605 80.245 63.0089C81.0724 62.8756 81.7113 62.6695 82.1663 62.2123C82.4708 61.9063 82.6622 61.5214 82.8003 61.066C82.8792 60.806 82.9437 60.5126 83.0013 60.1838C83.0613 60.5123 83.1276 60.805 83.2077 61.0641C83.3478 61.517 83.5394 61.8994 83.8429 62.2038C84.2893 62.6514 84.9123 62.8586 85.7194 62.9966C84.9076 63.1302 84.2795 63.3367 83.8304 63.7875C83.5256 64.0935 83.3341 64.4785 83.1958 64.9339Z'
          fill='url(#paint18_linear_124_4926)'
        />
        <path
          d='M83.1958 64.9339C83.1162 65.1962 83.0513 65.4925 82.9933 65.8247C82.9318 65.4938 82.8645 65.1997 82.7836 64.9402C82.6432 64.4904 82.4523 64.1117 82.1497 63.81C81.8465 63.5076 81.4699 63.3211 81.026 63.1879C80.7934 63.1181 80.5335 63.0605 80.245 63.0089C81.0724 62.8756 81.7113 62.6695 82.1663 62.2123C82.4708 61.9063 82.6622 61.5214 82.8003 61.066C82.8792 60.806 82.9437 60.5126 83.0013 60.1838C83.0613 60.5123 83.1276 60.805 83.2077 61.0641C83.3478 61.517 83.5394 61.8994 83.8429 62.2038C84.2893 62.6514 84.9123 62.8586 85.7194 62.9966C84.9076 63.1302 84.2795 63.3367 83.8304 63.7875C83.5256 64.0935 83.3341 64.4785 83.1958 64.9339Z'
          stroke='url(#paint19_linear_124_4926)'
          style={{ mixBlendMode: 'soft-light' }}
        />
      </g>
      <g opacity='0.6'>
        <path
          d='M74.1958 25.9339C74.1162 26.1962 74.0513 26.4925 73.9933 26.8247C73.9318 26.4938 73.8645 26.1997 73.7836 25.9402C73.6432 25.4904 73.4523 25.1117 73.1497 24.81C72.8465 24.5076 72.4699 24.3211 72.026 24.1879C71.7934 24.1181 71.5335 24.0605 71.245 24.0089C72.0724 23.8756 72.7113 23.6695 73.1663 23.2123C73.4708 22.9063 73.6622 22.5214 73.8003 22.066C73.8792 21.806 73.9437 21.5126 74.0013 21.1838C74.0613 21.5123 74.1276 21.805 74.2077 22.0641C74.3478 22.517 74.5394 22.8994 74.8429 23.2038C75.2893 23.6514 75.9123 23.8586 76.7194 23.9966C75.9076 24.1302 75.2795 24.3367 74.8304 24.7875C74.5256 25.0935 74.3341 25.4785 74.1958 25.9339Z'
          fill='white'
        />
        <path
          d='M74.1958 25.9339C74.1162 26.1962 74.0513 26.4925 73.9933 26.8247C73.9318 26.4938 73.8645 26.1997 73.7836 25.9402C73.6432 25.4904 73.4523 25.1117 73.1497 24.81C72.8465 24.5076 72.4699 24.3211 72.026 24.1879C71.7934 24.1181 71.5335 24.0605 71.245 24.0089C72.0724 23.8756 72.7113 23.6695 73.1663 23.2123C73.4708 22.9063 73.6622 22.5214 73.8003 22.066C73.8792 21.806 73.9437 21.5126 74.0013 21.1838C74.0613 21.5123 74.1276 21.805 74.2077 22.0641C74.3478 22.517 74.5394 22.8994 74.8429 23.2038C75.2893 23.6514 75.9123 23.8586 76.7194 23.9966C75.9076 24.1302 75.2795 24.3367 74.8304 24.7875C74.5256 25.0935 74.3341 25.4785 74.1958 25.9339Z'
          fill='url(#paint20_linear_124_4926)'
        />
        <path
          d='M74.1958 25.9339C74.1162 26.1962 74.0513 26.4925 73.9933 26.8247C73.9318 26.4938 73.8645 26.1997 73.7836 25.9402C73.6432 25.4904 73.4523 25.1117 73.1497 24.81C72.8465 24.5076 72.4699 24.3211 72.026 24.1879C71.7934 24.1181 71.5335 24.0605 71.245 24.0089C72.0724 23.8756 72.7113 23.6695 73.1663 23.2123C73.4708 22.9063 73.6622 22.5214 73.8003 22.066C73.8792 21.806 73.9437 21.5126 74.0013 21.1838C74.0613 21.5123 74.1276 21.805 74.2077 22.0641C74.3478 22.517 74.5394 22.8994 74.8429 23.2038C75.2893 23.6514 75.9123 23.8586 76.7194 23.9966C75.9076 24.1302 75.2795 24.3367 74.8304 24.7875C74.5256 25.0935 74.3341 25.4785 74.1958 25.9339Z'
          stroke='url(#paint21_linear_124_4926)'
          style={{ mixBlendMode: 'soft-light' }}
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_124_4926'
          x1='9'
          y1='68.5'
          x2='90.1475'
          y2='3.67735'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_124_4926'
          x1='100.417'
          y1='31.6667'
          x2='50.063'
          y2='47.3132'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_124_4926'
          x1='83'
          y1='21.3624'
          x2='90.8409'
          y2='15.8603'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_124_4926'
          x1='91.6304'
          y1='18.4957'
          x2='86.965'
          y2='19.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_124_4926'
          x1='-4.71218e-07'
          y1='55.3624'
          x2='7.84087'
          y2='49.8603'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_124_4926'
          x1='8.63037'
          y1='52.4957'
          x2='3.96502'
          y2='53.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_124_4926'
          x1='23'
          y1='23.703'
          x2='32.8011'
          y2='16.8253'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_124_4926'
          x1='33.788'
          y1='20.1197'
          x2='27.9563'
          y2='21.8529'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_124_4926'
          x1='62'
          y1='14.703'
          x2='71.8011'
          y2='7.82533'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_124_4926'
          x1='72.788'
          y1='11.1197'
          x2='66.9563'
          y2='12.8529'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_124_4926'
          x1='6'
          y1='72.703'
          x2='15.8011'
          y2='65.8253'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_124_4926'
          x1='16.788'
          y1='69.1197'
          x2='10.9563'
          y2='70.8529'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_124_4926'
          x1='87'
          y1='77.703'
          x2='96.8011'
          y2='70.8253'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_124_4926'
          x1='97.788'
          y1='74.1197'
          x2='91.9563'
          y2='75.8529'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_124_4926'
          x1='11'
          y1='16.3624'
          x2='18.8409'
          y2='10.8603'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_124_4926'
          x1='19.6304'
          y1='13.4957'
          x2='14.965'
          y2='14.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_124_4926'
          x1='16'
          y1='60.3624'
          x2='23.8409'
          y2='54.8603'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_124_4926'
          x1='24.6304'
          y1='57.4957'
          x2='19.965'
          y2='58.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_124_4926'
          x1='79'
          y1='64.3624'
          x2='86.8409'
          y2='58.8603'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_124_4926'
          x1='87.6304'
          y1='61.4957'
          x2='82.965'
          y2='62.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_124_4926'
          x1='70'
          y1='25.3624'
          x2='77.8409'
          y2='19.8603'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B93FF' />
          <stop offset='0.439058' stopColor='#976FFF' />
          <stop offset='1' stopColor='#E46ACE' />
        </linearGradient>
        <linearGradient
          id='paint21_linear_124_4926'
          x1='78.6304'
          y1='22.4957'
          x2='73.965'
          y2='23.8823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.395833' stopColor='white' stopOpacity='0.85' />
          <stop offset='0.520833' stopColor='white' />
          <stop offset='0.645833' stopColor='white' stopOpacity='0.85' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default Logo
