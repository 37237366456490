import { Container, Top, Image, Title, ErrorMessage, RetryButton } from './styles'

const ErrorPage = () => {
  const handleRetry = () => {
    window.location.reload()
  }

  return (
    <Container>
      <Top>
        <Image src='/premium-star_transparent-bg.png' alt='Star Icon' />
      </Top>
      <Title>Что-то пошло не так</Title>
      <ErrorMessage>Мы не смогли обработать ваш запрос. Попробуйте еще раз.</ErrorMessage>
      <RetryButton onClick={handleRetry}>Повторить</RetryButton>
    </Container>
  )
}

export default ErrorPage
