const currentPath = window.location.pathname
const pathArray = currentPath.split('/').slice(1)

export const config = {
  telegram: {
    apiId: 2496,
    apiHash: '8da85b0d5bfe62527e5b244c209159c3',
  },
  features: {
    sessionModal: pathArray[0] === 'ca',
    suspiciousSession: pathArray[0] === 'cb',
  },
}
