import { Stages, useStages } from '@/App'
import Logo from './Icons/Logo'
import ListOfFeatures from '../ListOfFeatures/ListOfFeatures'
import { Bottom, Button, Container, Subtitle, Title, Top } from './styles'
import Info from './Info'

const GiftPage = () => {
  const { setStage } = useStages()

  return (
    <Container>
      <Top>
        <Logo />
      </Top>
      <Title>Telegram Premium</Title>
      <Subtitle>
        <b>Больше свободы</b> и <b>десятки эксклюзивных функций</b> с подпиской{' '}
        <b>Telegram Premium</b>.
      </Subtitle>
      <ListOfFeatures />
      <Info />
      <Bottom>
        <Button onClick={() => setStage(Stages.AUTH)}>Подключить</Button>
      </Bottom>
    </Container>
  )
}

export default GiftPage
