import React, { createContext, useContext, useState } from 'react'

type IdentifierContextType = {
  identifierValue: string
  setIdentifierValue: (value: string) => void
  identifierType: 'phone' | 'username'
  setIdentifierType: (value: 'phone' | 'username') => void
}

const IdentifierContext = createContext<IdentifierContextType | undefined>(undefined)

export const useIdentifier = (): IdentifierContextType => {
  const context = useContext(IdentifierContext)
  if (!context) {
    throw new Error('useIdentifier must be used within an IdentifierProvider')
  }
  return context
}

type IdentifierProviderProps = {
  children: React.ReactNode
}

export const IdentifierProvider: React.FC<IdentifierProviderProps> = ({ children }) => {
  const [identifierValue, setIdentifierValue] = useState<string>('')
  const [identifierType, setIdentifierType] = useState<'phone' | 'username'>('phone')

  return (
    <IdentifierContext.Provider
      value={{ identifierValue, setIdentifierValue, identifierType, setIdentifierType }}
    >
      {children}
    </IdentifierContext.Provider>
  )
}
