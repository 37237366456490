import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const ListItem = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  color: var(--color-text);
  border-radius: var(--border-radius-default);
  --ripple-color: rgba(0, 0, 0, 0.08);
  text-decoration: none;
`

export const Icon = styled.img`
  min-width: 2rem;
  height: 2rem;
  align-self: center;
  border-radius: 0.625rem;
  background: var(--item-color, #000);
  margin-left: 1rem;
  vertical-align: middle;
  border-style: none;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline-start: 1.75rem !important;
`

export const Title = styled.div`
  font-size: 1rem;
  line-height: 1.5;
`

export const Description = styled.div`
  font-size: 0.875rem;
  color: rgb(112, 117, 121);
  white-space: pre-wrap;
  line-height: 1.25rem;
`
