import { styled } from 'styled-components'

const Info = () => {
  return (
    <Container>
      <div>
        <p>
          Бесплатная версия Telegram предлагает пользователям больше, чем любой другой мессенджер, а
          с Telegram Premium Ваши возможности станут ещё шире.
        </p>
        <p>
          Мы сделали подписку Telegram Premium платной, поскольку большинство премиум-функций
          требуют дополнительных расходов, в том числе на аренду дата-центров и покупку серверов.
          Вклад подписчиков позволяет нам покрывать эти расходы и помогает Telegram оставаться
          бесплатным для всех пользователей.
        </p>
      </div>
      <div>
        Оформляя подписку Telegram Premium, Вы принимаете условия{' '}
        <a
          href='https://telegram.org/tos/ru'
          title='https://telegram.org/tos/ru'
          target='_blank'
          rel='noopener noreferrer'
        >
          Пользовательского соглашения
        </a>
        &nbsp;и{' '}
        <a
          href='https://telegram.org/privacy/ru'
          title='https://telegram.org/privacy/ru'
          target='_blank'
          rel='noopener noreferrer'
        >
          Политики конфиденциальности
        </a>
        &nbsp;Telegram.
      </div>
    </Container>
  )
}

export default Info

const Container = styled.div`
  margin: 0.5rem;
  font-size: 0.875rem;
  color: #000;
  margin: 0.5rem;
  line-height: 1.5;

  & a {
    color: rgb(51, 144, 236);
    text-decoration: none;
    word-break: break-word;
  }

  & div:last-child {
    color: rgb(112, 117, 121);
  }
`
